body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* global colors */
  --main-bg-color: #2A7C6F;
  --main-fg-color: white;
  --secondary-bg-color: rgb(32, 229, 158);
  --secondary-fg-color: white;

}

/* Default fonts and colors */
.primary-background-color {
  color: white;
  background-color: #2A7C6F;
}
.secondary-color {
  color: #2fcc66;
}

.content {
  margin: 10px;
  padding: 20px;
}

/* Content Sections */
.content-primary-color {
  color: white;
  background-color: #2A7C6F;
  min-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


/* Footer */
.footer {
  color: white;
  background-color: #2A7C6F;
  min-height: 30px;
}
.copyright {
  padding: 9px;
  font-size: xx-small;
  text-align: left;
}
.footer-image {
  margin: auto;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
