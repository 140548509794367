.Home {
}

.Home-row {
	padding: 50px;
}

.Home-AppStore {
	margin-bottom: 20px;
}

.Home-banner {
}
.Home-demo-video-background {
	height: 500px;
	color: white;
	background-color: rgba(0,0,0,0.35);
}
.Home-demo-video-button:hover {
	color: rgba(255.0,255.0,255.0,0.6);
}
