/*.RecievePayment {
	max-width: 400px;
	height: 100px;
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left: auto;
	margin-right: auto;
	padding: 10px;
	font-size: 14px;
	font-weight: bold;
}
.Service-name {
	text-align: left;
	margin: 0px;
	padding: 0px;
}
.Service-detail {
	text-align: left;
	margin-bottom: 3px;
	font-weight: normal;
}
.Service-state {
	font-size:18px;
	font-weight: normal;
	text-align: center;
	margin-left: 40%;
	margin-right: 40%;
}*/

.ReceivePayment {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
