.SignUp {

}

.SignUp-Button {
	margin-bottom: 25px;
	margin-left: 12px;
}

table {
	margin-left: auto;
	margin-right: auto;
}

table tr td:nth-child(1) {
	/*float: left;*/
}
table tr td:nth-child(2) {
	text-align: right;
	margin: auto 0 auto auto;
	float: right;
	text-align: right;
	display: flex;
	align-items: flex-end;
	justify-content: right;
	horiz-align: right;
}

@media (min-width:768px) {

}

